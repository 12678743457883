import moment from 'moment-timezone';
import { number, object, string } from 'yup';
import {
  endDateValidation,
  endTimeValidation,
  startDateSimpleValidation,
  startTimeSimpleValidation,
} from '../../../../components/CampaignWizard/validations';

const defaultCampaignName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Campaign`;
};

export const initialValues = () => ({
  name: defaultCampaignName(),
  advertiserName: '',
  budgetType: 'lifetime_budget',
  lifetimeBudget: '',
  dailyBudget: '',
  // dailyBudgetCap: '',
  startDate: moment(),
  startTime: null,
  endDate: null,
  endTime: null,
  isDisplay: false,
  displayPct: null,
  objective: 'websiteSales',
  advancedOptions: {
    isIncremental: false,
    isExperiment: false,
    isCapAds: false,
    freqCaps: [],
    experimentPlan: null,
    experimentType: null,
  },
});

export const validationSchema = () =>
  object().shape(
    {
      status: string(),
      name: string().required('Required'),
      lifetimeBudget: number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        )
        .when('budgetType', {
          is: 'lifetime_budget',
          then: s =>
            s
              .required('Required')
              .min(0.01, 'Please enter a budget over one cent.'),
          otherwise: s => s.notRequired(),
        }),
      dailyBudget: number()
        .nullable()
        .transform((value, originalValue) =>
          originalValue === '' ? null : value,
        )
        .min(0.01, 'Please enter a daily budget over one cent.'),
      // dailyBudgetCap: number()
      //   .nullable()
      //   .transform((value, originalValue) =>
      //     originalValue === '' ? null : value,
      //   )
      //   .min(0.01, 'Please enter a daily bugdet cap over one cent.'),
      startDate: startDateSimpleValidation('endDate'),
      endDate: endDateValidation('startDate', 'budgetType'),
      startTime: startTimeSimpleValidation('startDate', 'endDate', 'endTime'),
      endTime: endTimeValidation('startDate', 'startTime', 'endDate'),
      objective: string().required('Required'),
    },
    [['endDate', 'endTime']],
  );
