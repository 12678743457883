import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material/';
import numeral from 'numeral';
import AdvertiserContext from '../AdvertiserContext';

import { Container } from './Container';
import { Icon } from './Icon';
import { Strategies } from './strategies';
import { Themes, Scopes } from '../../constants';
import { useAPI } from '../hooks/api';
import { useSnackbar } from 'notistack';
import { useUtil } from '../hooks/util';
import { usePermissions } from '../hooks';
import { useRoles } from '@components/hooks/roles';


const PREFIX = 'BidStrategy';

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.container}`]: {
    borderRadius: '10px 10px 2px 2px',
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.label}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: '#C2F2E2',
    fontSize: 16,
  }
}));

const SetFilterCallbacks = {
  [Themes.DEFAULT]: item =>
    item.display_name.toLowerCase().trim() !== 'flat cpm',
  [Themes.NBCU]: () => true,
};

const ErrorTypes = {
  BID_STRATEGY_REQUIRED: 'BID_STRATEGY_REQUIRED',
  EVENT_REQUIRED: 'EVENT_REQUIRED',
  SMALL_CPM_NOT_ALLOWED: 'SMALL_CPM_NOT_ALLOWED',
  CPM_REQUIRED: 'CPM_REQUIRED',
  TARGET_REQUIRED: 'TARGET_REQUIRED',
};

const FormErrorMsgs = {
  [ErrorTypes.BID_STRATEGY_REQUIRED]: 'Ad Group must have a Bid Strategy',
  [ErrorTypes.EVENT_REQUIRED]: 'Bid Strategy requires an event',
  [ErrorTypes.CPM_REQUIRED]: 'A CPM Bid is required',
  [ErrorTypes.SMALL_CPM_NOT_ALLOWED]: 'Bid cannot be lower than $18',
  [ErrorTypes.TARGET_REQUIRED]: 'Bid Strategy requires a target CPA',
};

export const BidStrategy = forwardRef((props, ref) => {

  const adContext = useContext(AdvertiserContext);
  const roles = useRoles();
  const { usePatch } = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const { useDebouncedValue } = useUtil();
  const { hasPermission } = usePermissions();

  const {
    adjustRecommendedBid = false,
    isInternalUser,
    cpmRange = [],
    handleBidStategyChange,
    adGroup,
    bidStrategy,
  } = props;

  const [expanded, setExpanded] = useState(adGroup?.bid_strategy || '');
  const [adjustedCPM, setAdjustedCPM] = useState(0);
  const canSetSmallFlatCPM = hasPermission([Scopes.CAN_SET_SMALL_FLAT_CPM]);

  const handleExpand = strategyUrl => (event, expanded) => {
    setExpanded(expanded ? strategyUrl : '');
  };

  const rangeText = useMemo(() => {
    const RECOMMENCED_ADJUSTMENT = 2.5;

    let [min, max] = cpmRange;
    if (!min || !max) return '';

    if (Math.ceil(min) === Math.ceil(max)) {
      if (adjustRecommendedBid) min = Number(min) + RECOMMENCED_ADJUSTMENT;
      return `${numeral(Math.ceil(min)).format('$0,0')}`;
    }

    if (adjustRecommendedBid) max = Number(max) + RECOMMENCED_ADJUSTMENT;
    return `${numeral(min).format('$0,0')} - ${numeral(Math.ceil(max)).format(
      '0,0'
    )}`;
  }, [cpmRange, adjustRecommendedBid]);

  const bidStrategySet = useMemo(() => {
    const setFilterCallback = SetFilterCallbacks[adContext.theme];
    const set = [...(adContext?.bidstrategy_set.filter(setFilterCallback) || [])];

    set.sort((a, b) => a.display_name.localeCompare(b.display_name));

    set.forEach(strategy => {
      const name = strategy.display_name.toLowerCase().trim();
      strategy.name = name;

      const commonExtraData = {
        adjustedCPM,
        cpmRange,
        infoMessage: 'Daily budget is always spent',
        isInternalUser,
        rangeText,
      };

      switch (name) {
        case 'max outcomes':
        case 'max roas':
        case 'max impressions':
          strategy.bidStrategyLabel = 'Max CPM Bid';
          strategy.uiStrategyComponent = name === 'max outcomes' ?
            'RequiredEventOptionalMaxCPMStrategy' : 'OptionalMaxCPMStrategy';
          strategy.extraData = commonExtraData;
          break;
        case 'manual bid':
          strategy.bidStrategyLabel = 'Manual Bid'
          strategy.uiStrategyComponent = 'RequiredCPMStrategy'
          strategy.extraData = commonExtraData;
          break;
        case 'auto bid':
          strategy.bidStrategyLabel = 'Auto Bid';
          strategy.uiStrategyComponent = 'OptionalMaxCPMStrategy';
          strategy.extraData = commonExtraData;
          break;
        case 'flat cpm':
          strategy.bidStrategyLabel = 'Flat CPM Bid';
          strategy.uiStrategyComponent = 'FlatCPMStrategy';
          strategy.extraData = {
            ...commonExtraData,
            canSetSmallFlatCPM,
            infoMessage: 'Minimum bid price is $18',
          };
          break;
        case 'target cost per outcome':
          strategy.bidStrategyLabel = 'Target Cost per Outcome';
          strategy.uiStrategyComponent = 'RequiredEventRequiredTargetStrategy';
          strategy.extraData = {
            infoMessage: 'Bidding stops if the Target CPA cannot be met',
            targetLabel: 'Target CPA',
          };
          break;
        default:
          console.log(`Using default Strategy for ${name}`);
          strategy.uiStrategyComponent = 'UnsupportedStrategy';
          strategy.description = 'No strategy form match';
      }
    });

    return set;
  }, [adContext?.bidstrategy_set, adContext.theme]);


  // TODO: replace this with proper permission from BE (This should only apply to NBCU)
  const userCanSetFlatCPMValue = cpm => (
    cpm >= 18 || roles.TENANT_ADMIN || canSetSmallFlatCPM
  );

  const validate = strategy => {
    const selectedBidStrategyType = bidStrategySet.find(
      item => item.url === strategy.bid_strategy
    );

    if (!selectedBidStrategyType) return ErrorTypes.BID_STRATEGY_REQUIRED;

    if (['max outcomes'].includes(selectedBidStrategyType.name)) {
      if (!strategy.bid_strategy_event) return ErrorTypes.EVENT_REQUIRED;
    }

    const isNBCUTheme = adContext.theme == Themes.NBCU;
    const isCPMTooSmall = !userCanSetFlatCPMValue(strategy.cpm);
    const isFlatCPMStrategy = selectedBidStrategyType.name === 'flat cpm';
    if (isNBCUTheme && isFlatCPMStrategy && isCPMTooSmall) {
      return ErrorTypes.SMALL_CPM_NOT_ALLOWED;
    }

    if (selectedBidStrategyType.name === 'flat cpm' && !strategy.cpm) {
      return ErrorTypes.CPM_REQUIRED;
    }

    if (selectedBidStrategyType.name == 'target cost per outcome') {
      if (!strategy.bid_strategy_event) return ErrorTypes.EVENT_REQUIRED;

      if (!strategy.bid_strategy_target) return ErrorTypes.TARGET_REQUIRED;
    }

    if (selectedBidStrategyType.name == 'manual bid' && (!strategy.cpm || strategy.cpm <= 0)) {
      return ErrorTypes.CPM_REQUIRED;
    }

    return null;
  };

  useImperativeHandle(ref, () => ({
    isValid(strategy) {
      const formErrorType = validate(strategy);
      return !formErrorType;
    },
    errorMsg(strategy) {
      const formErrorType = validate(strategy);
      if (!formErrorType) return null;

      return FormErrorMsgs[formErrorType];
    },
  }));

  const debouncedMaxBid = useDebouncedValue(bidStrategy?.cpm, 500);

  useEffect(() => {
    if (isInternalUser && adGroup?.id) {
      getMaxRecBid(debouncedMaxBid);
    }
  }, [debouncedMaxBid, isInternalUser, adGroup]);

  const handleFormChange = (target, value) => {
    if (target == 'bid_strategy') {
      setExpanded(value);
      handleBidStategyChange({
        cpm: null,
        bid_strategy_event: null,
        bid_strategy_target: null,
        bid_strategy: value,
      });
    } else {
      handleBidStategyChange({
        ...bidStrategy,
        [target]: value,
      });
    }
  };

  const getMaxRecBid = async bid => {
    if (!bid) {
      return;
    }
    try {
      const response = await usePatch(`/lineitems/${adGroup.id}/`, {
        cpm: bid,
      });
      const { adjusted_cpm } = response.data;
      setAdjustedCPM(adjusted_cpm);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error getting the Max CPM Bid', {
        variant: 'error',
      });
    }
  };

  return (
    <StyledGrid container direction="column">
      <Grid item>
        <Container className={classes.container}>
          <div className={classes.label}>Select a bid strategy</div>
          <Icon />
        </Container>
      </Grid>
      <Grid item>
        {bidStrategySet?.map(strategy => {
          const Strategy = Strategies[strategy.uiStrategyComponent];

          return (
            <Strategy
              key={strategy.id}
              strategy={strategy}
              currentStrategy={bidStrategy}
              events={adContext.bidstrategyevent_set}
              isExpanded={expanded === strategy.url}
              handleExpand={handleExpand(strategy.url)}
              handleFormChange={handleFormChange}
              extraData={strategy.extraData}
            />
          );
        })}
      </Grid>
    </StyledGrid>
  );
});

BidStrategy.propTypes = {
  isInternalUser: PropTypes.bool.isRequired,
  handleBidStategyChange: PropTypes.func.isRequired,
  adGroup: PropTypes.object.isRequired,
  adjustRecommendedBid: PropTypes.bool,
  cpmRange: PropTypes.array,
  bidStrategy: PropTypes.object.isRequired,
};

BidStrategy.displayName = 'BidStrategy';
