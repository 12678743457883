import { Themes } from '@constants/themes';

export const sections = {
  dates: 'dates',
};

export const fields = {
  name: {
    path: 'name',
  },
  advertiserName: {
    path: 'advertiserName',
  },
  budgetType: {
    path: 'budgetType',
  },
  lifetimeBudget: {
    path: 'lifetimeBudget',
  },
  dailyBudget: {
    path: 'dailyBudget',
  },
  dailyBudgetCap: {
    path: 'dailyBudgetCap',
  },
  startDate: {
    path: 'startDate',
  },
  endDate: {
    path: 'endDate',
    clearable: true,
  },
  startTime: {
    path: 'startTime',
  },
  endTime: {
    path: 'endTime',
    clearable: true,
  },
  isDisplay: {
    path: 'isDisplay',
  },
  displayPct: {
    path: 'displayPct',
  },
  objective: {
    path: 'objective',
  },
  advancedOptions: {
    path: 'advancedOptions',
  },
  active: {
    path: 'active',
  },
  draft: {
    path: 'draft',
  },
  budget: {
    path: 'budget',
  },
  status: {
    path: 'status',
  },
  advertiser: {
    path: 'advertiser',
  },
};

export const Copies = {
  [Themes.DEFAULT]: {
    advancedOptionsLabel: 'Advanced campaign delivery options',
    dragToggleText: 'Retarget exposed audience with display ads',
    experimentLabel: 'A/B test:',
    frequencyCapLabel: 'Frequency cap:',
    incrementalityLabel: 'Test incrementality:',
  },
  [Themes.NBCU]: {
    advancedOptionsLabel: 'Advanced Campaign Delivery Options',
    dragToggleText: 'Retarget Exposed Audience with Display Ads',
    experimentLabel: 'A/B Test:',
    frequencyCapLabel: 'Frequency Cap:',
    incrementalityLabel: 'Test Incrementality:',
  },
};
