import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import AdvertiserContext from './AdvertiserContext';
import { useUtil } from './hooks/util';
import { useCopy } from './hooks';
import { Themes } from '../constants';

const PREFIX = 'FrequencyCaps';

const classes = {
  container: `${PREFIX}-container`,
  addBtn: `${PREFIX}-addBtn`,
  adsEvery: `${PREFIX}-adsEvery`,
  cap: `${PREFIX}-cap`,
  capWrap: `${PREFIX}-capWrap`,
  delete: `${PREFIX}-delete`,
  divider: `${PREFIX}-divider`,
  dropdown: `${PREFIX}-dropdown`,
  header: `${PREFIX}-header`,
  input: `${PREFIX}-input`,
  manage: `${PREFIX}-manage`,
  numbered: `${PREFIX}-numbered`
};

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'appTheme',
})(({ theme, appTheme }) => ({
  [`&.${classes.container}`]: {
    backgroundColor: theme.palette.background.overlay,
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(8),
    paddingTop: theme.spacing(22),
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 6,
  },

  [`& .${classes.addBtn}`]: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.adsEvery}`]: {
    color: theme.palette.text.overlay,
    fontWeight: theme.typography[appTheme === Themes.DEFAULT ? 'fontWeightBold' : 'fontWeightLight'],
  },

  [`& .${classes.cap}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.capWrap}`]: {
    maxHeight: 370,
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  [`& .${classes.delete}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.divider}`]: {
    backgroundColor: '#e0e0e0',
  },

  [`& .${classes.dropdown}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',

    '& .MuiInputBase-root': {
      color: theme.palette.text.overlay,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.overlay,
    },
  },

  [`& .${classes.header}`]: {
    color: theme.palette.text.overlay,
  },

  [`& .${classes.input}`]: {
    '& .MuiInputBase-root': {
      borderColor: theme.palette.border.overlay,
      color: theme.palette.text.overlay,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.overlay,
    },
  },

  [`&.${classes.manage}`]: {
    paddingTop: theme.spacing(8),
  },

  [`& .${classes.numbered}`]: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: `50%`,
    color: `#fff`,
    display: `inline-block`,
    padding: `2px`,
    height: `1.5rem`,
    width: `1.5rem`,
    textAlign: 'center',
    marginRight: theme.spacing(2),
  }
}));

const Copies = {
  [Themes.DEFAULT]: {
    HEAD: 'Configure frequency cap',
    INTRO: null,
  },
  [Themes.NBCU]: {
    HEAD: 'Configure Frequency Cap',
    INTRO: 'Set a frequency cap for your campaign to determine how often users should see ads from your campaign.',
  }
};

const FrequencyCaps = props => {
  const adContext = useContext(AdvertiserContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { compareTo30days, deepCompare } = useUtil();
  const Copy = useCopy(Copies);

  const { freqCaps, setFreqCaps, setIsFreqCaps } = props;

  const [caps, setCaps] = useState([]);
  const [duration, setDuration] = useState('');
  const [impressions, setImpressions] = useState('');
  const [freqType, setFreqType] = useState('');
  const [isFreqError, setIsFreqError] = useState(false);

  useEffect(() => {
    if (freqCaps && freqCaps.length > 0) {
      setCaps(freqCaps);
    }
  }, [freqCaps]);

  useEffect(() => {
    if (
      duration !== '' && parseInt(duration, 10) !== 0 &&
      impressions !== '' && parseInt(impressions, 10) !== 0 &&
      freqType !== ''
    ) {
      const isValid = compareTo30days(duration, freqType);

      if (isValid) {
        closeSnackbar();
        setIsFreqError(false);
      }

      if (!isValid) {
        setIsFreqError(true);

        enqueueSnackbar('Frequency cannot exceed 30 days total', {
          autoHideDuration: 3000,
          preventDuplicate: true,
          variant: 'warning',
        });
      }
    }
  }, [duration, impressions, freqType])

  const isAddDisabled = useMemo(() => {
    return !(
      duration !== '' && parseInt(duration, 10) !== 0 &&
      impressions !== '' && parseInt(impressions, 10) !== 0 &&
      freqType !== '' && !isFreqError
    )
  }, [duration, impressions, freqType, isFreqError]);

  const isSaveDisabled = useMemo(() => {
    return (
      (freqCaps.length === 0 && caps.length === 0) ||
      deepCompare(freqCaps, caps)
    )
  }, [freqCaps, caps])

  const handleAddCap = () => {
    const dataCap = {
      duration,
      impressions,
      fctype: freqType,
    };

    setCaps(prev => ([...prev, dataCap]));

    setDuration('');
    setImpressions('');
    setFreqType('');
  };

  const handleDeleteCap = (cap, index) => {
    setCaps(prev => prev.filter((p, i) => i !== index));
  };

  const handleSaveCapAds = () => {
    setFreqCaps(caps);
    setIsFreqCaps(false);
  };

  const renderFreqCap = (cap, index) => (
    <Box
      key={`${cap.fctype}-${index}`}
      appTheme={adContext.theme}
      width="100%"
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Box display="flex">
          <Box>
            <Typography className={classes.numbered} variant="body2">
              {index + 1}
            </Typography>
          </Box>

          <Box>
            <Typography className={classes.cap}>
              {cap.impressions} ads every&nbsp;
              {cap.duration} {cap.fctype.toLowerCase()}(s)
            </Typography>
          </Box>
        </Box>

        <Box>
          <IconButton
            className={classes.delete}
            aria-label="delete"
            onClick={() => handleDeleteCap(cap, index)}
            size="large">
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Box my={3}>
        <Divider className={classes.divider} />
      </Box>
    </Box>
  );

  return (
    <StyledBox className={clsx(classes.container, props.isManage ? classes.manage : {})}>
      <Box>
        <Typography className={classes.header} variant="h3">{Copy.HEAD}</Typography>

        {Copy.INTRO && <p className={classes.header}>{Copy.INTRO}</p>}

        <Box my={3}>
          <Divider className={classes.divider} />
        </Box>

        <Box className={classes.capWrap} width="100%">
          {caps && caps.length > 0 && caps.map((c, i) => renderFreqCap(c, i))}
        </Box>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item container xs={8} spacing={1}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
            >
              <Grid item xs={3}>
                <TextField
                  id="ad-cap-count"
                  data-testid="ad-cap-count"
                  className={classes.input}
                  autoFocus
                  color="secondary"
                  variant="outlined"
                  value={impressions}
                  onChange={event => setImpressions(event.target.value)}
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </Grid>

              <Grid item container xs={2} justifyContent="center">
                <Grid item>
                  <Typography className={classes.adsEvery}>
                    ads every
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  data-testid="ad-cap-duration"
                  error={isFreqError}
                  className={classes.input}
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  value={duration}
                  onChange={event => setDuration(event.target.value)}
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <FormControl variant="standard" className={classes.dropdown}>
                  <Select
                    color="secondary"
                    defaultValue="day"
                    variant="outlined"
                    labelId="ad-cap-dropdown"
                    value={freqType}
                    SelectDisplayProps={{ "aria-label": "freq type" }}
                    onChange={event => setFreqType(event.target.value)}
                    fullWidth
                  >
                    <MenuItem value={'MIN'}>
                      Minute(s)
                    </MenuItem>

                    <MenuItem value={'HOUR'}>
                      Hour(s)
                    </MenuItem>

                    <MenuItem value={'DAY'}>
                      Day(s)
                    </MenuItem>

                    <MenuItem value={'WEEK'}>
                      Week(s)
                    </MenuItem>

                    {/* TODO: API needs to accept MONTH as a fctype */}
                    {/* <MenuItem
                          disabled={selectedTypes.includes('MONTH')}
                          value={'MONTH'}
                        >
                          Month(s)
                        </MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              disabled={isAddDisabled}
              className={classes.addBtn}
              color="secondary"
              onClick={handleAddCap}
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => setIsFreqCaps(false)}
              color="secondary"
              size="small"
              className="Button--small"
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled={isSaveDisabled}
              onClick={handleSaveCapAds}
              color="secondary"
              variant="contained"
              size="medium"
              className="Button--medium"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  );
};

FrequencyCaps.propTypes = {
  isManage: PropTypes.bool,
  freqCaps: PropTypes.array,
  setIsFreqCaps: PropTypes.func,
  setFreqCaps: PropTypes.func,
};

export default FrequencyCaps;
