import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import {
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Text } from '../campaign/Text';
import { faCheck, faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@v2/components/OptionsAccordion/Accordion';
import { TvSciQSvgIcon } from '../icons/TvSciQSvgIcon';
import useLockedStates from './hooks/useLockedStates';
import { useDomain } from '@components/hooks/domain';

const RadioStyled = styled(Radio)(({ theme }) => ({
  color: theme.palette.blue[6],
  '&.Mui-checked': {
    color: theme.palette.blue[6],
  },
}));

// Define the styled component for the header
const HeaderBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.blue[1],
  borderRadius: '10px 10px 2px 2px',
  display: 'flex',
  fontFamily: theme.typography.fontFamily,
  justifyContent: 'center',
  padding: '16px 24px 15px 24px',
  width: '100%',
}));

// StyledAccordion applies custom styles to MUI Accordion
const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  maxWidth: '100%',
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    cursor: expanded ? 'default' : 'pointer',
  },
  '& .MuiAccordionSummary-content': {
    maxWidth: '100%',
  },
  backgroundColor: expanded
    ? theme.palette.blue[11]
    : theme.palette.common.offWhite,
  borderRadius: '2px',
  marginBottom: '12px',
  transition: 'background-color 0.3s',
}));

// Styled faCircleInfo for open accordion
const CheckIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2.25),
}));

// Styled faLockAlt for locked accordion
const LockIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[3],
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2.25),
}));

// Styled container for the icon and text
const IconTextContainer = styled('div')(({ theme }) => ({
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
}));

// Styled Typography for the "tvSc" part of the text
const StyledTypography = styled(Typography)({
  marginLeft: '5px',
});

// Styled span for the "iQ" part with a heavier font weight
const BoldText = styled('span')({
  fontWeight: 700,
});

const TvSciQIconWithText = () => {
  const theme = useTheme();
  return (
    <IconTextContainer>
      <TvSciQSvgIcon fill={theme.palette.green[8]} />
      <StyledTypography component="span">
        tvSc<BoldText>iQ</BoldText>
      </StyledTypography>
    </IconTextContainer>
  );
};

const StyledAlertBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.chart[6],
  boxSizing: 'border-box',
  clear: 'both',
  color: theme.palette.text.primary,
  display: 'flex',
  padding: theme.spacing(1),
  width: '100%',
}));

const StyledInfoBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'trackingIsSetUp',
})(({ theme, trackingIsSetUp }) => ({
  alignItems: 'center',
  backgroundColor: trackingIsSetUp
    ? theme.palette.green[10]
    : theme.palette.grey[11], // Green background if tracking is set up
  boxSizing: 'border-box',
  clear: 'both',
  color: trackingIsSetUp
    ? theme.palette.green[3]
    : theme.palette.text.primary, // Green text if tracking is set up
  display: 'flex',
  padding: theme.spacing(1),
  paddingLeft: '15px',
  width: '100%',
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ expanded }) => ({
  maxWidth: '100%',
  pointerEvents: expanded ? 'none' : 'auto',
  cursor: expanded ? 'default' : 'pointer',
  '& .MuiFormControlLabel-label': {
    maxWidth: '100%',
  },
}));

const renderInfoBox = (
  trackingIsSetUp,
  key,
  isRecommended,
  isLocked,
  text,
) => {
  // Check if the option is recommended and not locked while expanded
  if (isRecommended && !isLocked) {
    return (
      <StyledInfoBox key={key} trackingIsSetUp={trackingIsSetUp}>
        <Typography component="span" variant="body2">
          {text}
        </Typography>
      </StyledInfoBox>
    );
  }
  return null;
};

const ProTipBox = ({ text }) => {
  return (
    <StyledInfoBox>
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    </StyledInfoBox>
  );
};
const toTypography = (value, typographyProps) =>
  React.isValidElement(value) ? (
    <>{value}</>
  ) : (
    <Text {...typographyProps}>{value}</Text>
  );

// Define PropTypes for ProTipBox component
ProTipBox.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Define 'text' prop as a required string
};

const InfoTextBoxStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[3],
}));

const InfoTextTypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[3],
  fontSize: '0.75rem',
  paddingTop: theme.spacing(0.45),
}));

const InfoText = ({ text }) => {
  return (
    <Stack direction="row" spacing={1} mb={2}>
      <InfoTextBoxStyled>
        <FontAwesomeIcon
          icon={faCircleInfo}
        />
      </InfoTextBoxStyled>
      <InfoTextTypographyStyled>
        {text}
      </InfoTextTypographyStyled>
    </Stack>
  );
};

// Define PropTypes for InfoText component
InfoText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // Define 'text' prop as a required string
};

/**
 * OptionsAccordion displays a list of options in an accordion format.
 * It allows a user to select a options by expanding an accordion item and selecting the radio button.
 *
 * @param {string} name - The name attribute for the radio input group, to ensure they are part of the same group.
 * @param {string} headerTitle - The title displayed at the top of the accordion component.
 * @param {boolean} trackingIsSetUp - Indicates whether tracking has been set up, which affects the locked state of options.
 * @param {Object[]} campaignOptions - An array of objects containing the properties of each option. Each object should have:
 *        {string} value - The value attribute for the radio input.
 *        {string} label - The display label for the option.
 *        {string} description - A brief description of the option.
 *        {string} infoText - Additional information about the option.
 *        {string} proTip - Pro Tip about the option.
 *        {string} lockedContent - The content to display for the locked option when the accordion is expanded.
 *        {string} unlockedContent - The content to display the unlocked option when the accordion is expanded.
 *        {boolean} recommended - Indicates if the option is recommended.
 *        {string} recommendedInfo - The recommended info bar text.
 *        {boolean} locked - Indicates if the option is locked and not selectable.
 *        {string} lockedInfo - The locked info bar text.
 *
 * @example
 * <OptionsAccordion
 *   name="campaign-objective"
 *   headerTitle="Select a Campaign Objective"
 *   trackingIsSetUp={false}
 *   campaignOptions={[
 *     {
 *       value: 'appInstalls',
 *       label: 'App Installs',
 *       description: 'Drive new users to download your app.',
 *       infoText: 'Drive more app installs with targeted advertising.',
 *       lockedContent: 'Detailed information about app installs.',
 *       unlockedContent: (
 *         <FormControl fullWidth>
 *          <InputLabel id="app_installs_event">Conversion Event</InputLabel>
 *          <Select
 *            labelId="app_installs_event"
 *            id="app_installs_select"
 *            value="app_install"
 *            label="Conversion Event"
 *          >
 *            <MenuItem value="app_install">app_install</MenuItem>
 *          </Select>
 *        </FormControl>,
 *        recommended: true,
 *        recommendedInfo: 'Recommended based on your advertiser type',
 *        locked: true,
 *        lockedInfo: 'This bid strategy will become available once tracking is set up.',
 *     },
 *     // ...other objectives
 *   ]}
 * />
 */
const OptionsAccordion = forwardRef(
  (
    {
      name,
      trackingIsSetUp,
      headerTitle,
      campaignOptions,
      onChange,
      value,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme(); // Access theme using the useTheme hook
    const domain = useDomain();
    const [
      lockedOptionsExpandedStates,
      setLockedOptionsExpandedStates
    ] = useLockedStates(campaignOptions); // separate isExpanded state management for locked options

    const isPeacockDomain = domain.peacock;

    const handleChange =
      panel =>
        (...args) => {
          const option = campaignOptions.find(obj => obj.value === panel);
          if (option && option.locked) {
            setLockedOptionsExpandedStates({
              ...lockedOptionsExpandedStates,
              [panel]: !lockedOptionsExpandedStates[panel],
            });
          } else if (option && !option.locked) {
            onChange?.(...args, option);
          }
        };

    return (
      <Stack spacing={2}>
        <HeaderBox>
          <Typography
            variant="body1"
            color="common.white"
            sx={{ flexGrow: 1 }}
          >
            {headerTitle}
          </Typography>
          {!isPeacockDomain && <TvSciQIconWithText />}
        </HeaderBox>
        <RadioGroup
          {...props}
          ref={ref}
          name={name}
          value={value}
          onChange={handleChange}
        >
          {campaignOptions.map((option, index) => {
            const panelId = option.value;

            const isExpanded = (value === panelId) || (lockedOptionsExpandedStates[panelId] ?? false);
            const isRecommended = option.recommended;

            return (
              <StyledAccordion
                key={option.value}
                expanded={isExpanded}
                onChange={handleChange(panelId)}
                elevation={5}
                topBar={
                  <>
                    {isExpanded && option.locked && (
                      <StyledAlertBox
                        key={`alert-box-${index}`}
                        sx={{ paddingLeft: '20px' }}
                      >
                        <FontAwesomeIcon
                          icon={faLockAlt}
                          style={{
                            color: theme.palette.grey[3],
                            marginRight: '8px',
                          }}
                        />
                        <Typography component="span" variant="body2">
                          {option.lockedInfo}
                        </Typography>
                      </StyledAlertBox>
                    )}

                    {renderInfoBox(
                      trackingIsSetUp,
                      `info-${index}`,
                      isRecommended,
                      option.locked,
                      option.recommendedInfo,
                    )}
                  </>
                }
              >
                <AccordionSummary
                  key={`summary-${index}`}
                  expandIcon={null}
                >
                  <StyledFormControlLabel
                    expanded={isExpanded}
                    control={
                      <RadioStyled
                        checked={isExpanded && !option.locked}
                        disabled={option.locked}
                        inputProps={{
                          'data-testid': `radio-${option.label.toLowerCase().replace(' ', '-')}`,
                        }}
                      />
                    }
                    value={option.value}
                    label={
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          maxWidth: '100%',
                          paddingRight: '40px',
                        }}
                        spacing={1}
                      >
                        <Box
                          sx={{
                            maxWidth: '100%',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                            }}
                          >
                            {toTypography(option.label, {
                              sx: {
                                color: theme.palette.grey[1],
                                fontSize: '0.875rem',
                                fontWeight: '700',
                                marginRight: '8px',
                                width: 'auto',
                              },
                            })}
                            {option.infoText && !isExpanded && (
                              <Tooltip
                                title={option.infoText}
                                arrow
                                disableInteractive
                                enterTouchDelay={0}
                              >
                                  <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    style={{
                                      color: theme.palette.grey[8],
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  />
                              </Tooltip>
                            )}
                          </Box>
                          {toTypography(option.description, {
                            sx: {
                              color: theme.palette.grey[3],
                              fontSize: '0.875rem',
                              fontWeight: '400',
                            },
                          })}
                        </Box>
                        <Box>
                          {
                            // Only render FontAwesomeIcon if there is a valid icon to display
                            isExpanded
                              ? (option.locked ? null : (
                                  <CheckIconStyled
                                    icon={faCheck}
                                  />
                                ))
                              : (option.locked ? (
                                  <LockIconStyled
                                    icon={faLockAlt}
                                  />
                                ) : null)
                          }
                        </Box>
                      </Stack>
                    }
                  />
                </AccordionSummary>

                <AccordionDetails sx={{ padding: '0 45px' }}>
                  {!option.locked &&
                    option.unlockedContent &&
                    toTypography(option.unlockedContent, {
                      color: theme.palette.grey[1],
                    })}

                  {option.lockedContent &&
                    toTypography(option.lockedContent, {
                      color: theme.palette.grey[1],
                    })}

                  {option.infoText && <InfoText text={option.infoText} />}

                  {option.proTip && <ProTipBox text={option.proTip} />}
                </AccordionDetails>
              </StyledAccordion>
            );
          })}
        </RadioGroup>
      </Stack>
    );
  },
);

OptionsAccordion.displayName = 'OptionsAccordion';

// Prop types for OptionsAccordion
OptionsAccordion.propTypes = {
  ...RadioGroup.propTypes,
  headerTitle: PropTypes.string.isRequired,
  trackingIsSetUp: PropTypes.bool.isRequired,
  campaignOptions: PropTypes.array.isRequired,
};

export default OptionsAccordion;
